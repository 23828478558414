import { defineStore } from "pinia";
import EntityApi from "@/api/entityApi";

export const useMenuStore = defineStore("menu", {
  state: () => ({
    menu: [],
  }),
  getters: {},
  actions: {
    initMenuStore() {
      this.menu = [];
      console.log("initMenuStore");
    },
    async getUserMenu() {
      let res = await EntityApi.getTopbarMenu();
      if (res) {
        this.menu = res;
      } else return null;
    },
    findMenuItemByProperty(property, value) {
      function findItem(target, property, newValue) {
        let result = false;
        function find(target, property, newValue) {
          if (Array.isArray(target)) {
            for (const element of target) {
              if (Array.isArray(element)) {
                find(element, property, newValue);
              }
              if (element[property] === value) {
                result = element;
                break;
              }
              if (element?.items) {
                find(element.items, property, newValue);
              }
            }
          }
        }
        find(target, property, newValue);
        return result;
      }
      return findItem(this.menu, property, value);
    },
  },
});
