<template>
  <div class="w-100 d-flex" style="max-width: 100%">
    <dashboard></dashboard>
  </div>
</template>
<script>
import Dashboard from "../components/Dashboard";

export default {
  name: "home Page",
  props: {},
  components: { Dashboard },
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>
<style lang="scss"></style>
