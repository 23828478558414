import { createApp, markRaw } from "vue";
import App from "./App.vue";
import { createPinia } from "pinia";
import router from "./router";
import VueAxios from "vue-axios";
import axios from "axios";
import CustomElement from "./components/formGenerator/builder/CustomElement.vue";
import fieldGroup from "./components/formGenerator/builder/fields/fieldGroup.vue";
import InstanceCard from "./pages/entityPages/InstanceCard.vue";
import VueApexCharts from "vue3-apexcharts";
import { abilitiesPlugin } from "@casl/vue";
import ability from "./services/ability";
import { createI18n } from "vue-i18n/index";
import { getUserLocale, loadLocaleMessages } from "./i18n";
import directives from "./directives/";
import { setupCalendar } from "v-calendar";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import JSON_Editor from "./helpers/jsonEditor.js";
import Notifications from "notiwind";

customElements.define("json-editor", JSON_Editor);

let userLocale = getUserLocale();
const i18n = createI18n({
  legacy: true,
  locale: userLocale,
  fallbackLocale: "en",
  messages: loadLocaleMessages(),
});

const pinia = createPinia();
pinia.use(({ store }) => {
  store.router = markRaw(router);
});

export const app = createApp(App);
app.config.unwrapInjectedRef = true;
// app.config.compilerOptions.isCustomElement = (tag) => {
//   return tag.startsWith('json-') // (return true)
// }
app
  .use(pinia)
  .use(i18n)
  .use(VueAxios, axios)
  .use(router)
  .use(VueApexCharts)
  .use(abilitiesPlugin, ability, {
    useGlobalProperties: true,
  })
  .use(setupCalendar, {})
  .use(Notifications);
directives(app);
app.provide("axios", app.config.globalProperties.axios);
app.component("CustomElement", CustomElement);
app.component("fieldGroup", fieldGroup);
app.component("InstanceCard", InstanceCard);
app.component("VueDatePicker", VueDatePicker);
app.mount("#app");
